import React, { useState, useCallback, useRef } from "react"
import { Link } from "gatsby"
import { captureEvent } from 'event-service/modules/capture'

import grid from '../../scss/flexboxgrid.module.scss'
import view from './footer.module.scss'
import CookiePolicy from "../cookie/CookiePolicy"

function Footer(props) {
  const { siteNav, dealerInfo, footer, footerClassName } = props

  const observer = useRef()
  const [renderLazyItem, toggleLazyItem] = useState(false)
  const lazyItem = useCallback((node) => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        toggleLazyItem(true)
      }
    });
    if (node)
      observer.current.observe(node);
  })

  const renderSublinks = (sublinks) => (
    <ul className={view["foot-sublinks"]}>
        {
          sublinks.map((item, idx) => (
            <li>
              <Link id='nav_link' key={idx} className={`${view["btn-text-sublink"]}`} to={item.linkValue}>
                <span>{item.linkName}</span>
              </Link>
            </li>))
        }
    </ul>
  )
  const renderLinks = () => (
    <div className={view["foot-cont"]}>
      {
        footer.links.map((item, idx) => {
          if (item.subLinks.length <= 0)
          return(
            <li className={`${view["col-1"]}`}>
              <Link id='nav_link' key={idx} className={`${view["btn-text"]}` }
                    to={item.linkValue}>
                <span>{item.linkName}</span>
              </Link>
            </li>
          )
        })
      }
    </div>
  )
  const renderLinkColumns = () => (
    <ul className={view["foot-link-header"]}>
      {
        footer.links.map((item, idx) => {
          if (item.subLinks.length > 0)
          return(
            <li className={`${view["solo-col"]}`}>
              <Link id='nav_link' key={idx} className={`${view["btn-text"]}` }
                    to={item.linkValue}>
                <span>{item.linkName}</span>
              </Link>
              {item.subLinks.length > 0 ? renderSublinks(item.subLinks) : ''}
            </li>
          )
        })
      }
    </ul>
  )
  const renderBottomLinks = () => (
    <div className={view["divider-box"]}>
      {
        footer.bottomLinks.map((item, idx) => {
          return(
            <Link id='nav_link' to={item.linkValue} className={view["subtitle"]}>{item.linkName}</Link>
          )
        })

      }
      <p className={` ${view["subtitle"]} ${view["last"]}`} style={{lineHeight: "1"}}>Copyright &#169; {dealerInfo.dealerName} all rights reserved</p>
    </div>
  )

  const renderSocialColumn = () => {
    if(usableLink(footer.linkFB) || usableLink(footer.linkInsta) || usableLink(footer.linkTwit) || usableLink(footer.linkYelp) || usableLink(footer.linkYT) || usableLink(footer.linkLI)) 
    return(
         <div className={view["social-cont"]}>
          <h3>Connect With Us</h3>
          <div className={view['social-icon-list']}>
            {usableLink(footer.linkFB) ?
                <Link id='nav_link' to={footer.linkFB} alt="Our Facebook" className={`${view["social-icon"]} ${view["fb"]}`}></Link> : <span></span>}
            {usableLink(footer.linkInsta) ?
                <Link id='nav_link' to={footer.linkInsta} alt="Our Instagram" className={`${view["social-icon"]} ${view["insta"]}`}></Link> : <span></span>}
            {usableLink(footer.linkTwit) ?
                <Link id='nav_link' to={footer.linkTwit} alt="Our Twitter" className={`${view["social-icon"]} ${view["twitter"]}`}></Link> : <span></span>}
            {usableLink(footer.linkYelp) ?
                <Link id='nav_link' to={footer.linkYelp} alt="Our Yelp" className={`${view["social-icon"]} ${view["yelp"]}`}></Link> : <span></span>}
            {usableLink(footer.linkYT)?
                <Link id='nav_link' to={footer.linkYT} alt="Our Youtube" className={`${view["social-icon"]} ${view["youtube"]}`}></Link> : <span></span>}
            {usableLink(footer.linkLI) ?
                <Link id='nav_link' to={footer.linkLI} alt="Our LinkedIn" className={`${view["social-icon"]} ${view["linked"]}`}></Link> : <span></span>}
          </div>
        </div>
  )}

  const renderAppColumn = () => {
    if(usableLink(footer.linkIOS) || usableLink(footer.linkAndroid)) 
    return (
    <div className={view["app-cont"]}>
      {  <h3>Download Our App</h3>}
      { footer.linkIOS != '' ? <Link id='nav_link' to={footer.linkIOS} alt="Our IOS App" className={`${view["iosBtn"]} ${view["ios"]}`}></Link> : <span></span>}
      { footer.linkAndroid != '' ? <Link id='nav_link' to={footer.linkAndroid} alt="Our Android App" className={`${view["AndroidBtn"]} ${view["android"]}`}></Link> : <span></span>}
    </div>
  )}

  const loadFooterImage = () => {
    return typeof window !== 'undefined' && (
      <Link id='nav_link' to="/"><img className={view["foot-img"]} alt={"Footer Logo"} src={footer.logo}/></Link>
    )
  }

  const usableLink = (link) => {
    const notDefined = (link == undefined)
    const emptyString = !notDefined && link.replace(" ","") === ""
    return !(notDefined || emptyString)
  }
  return (
    <footer id="footer" ref={lazyItem} className={view["footer"] + " " + (footerClassName === undefined ? "" : footerClassName)}>
      <button className={view["scrollTop"]} onClick={() => {window.scrollTo({top: 0, behavior: 'smooth'});}}>Top <span className={view["dash"]}></span></button>
      <div className={`${grid["row"]} ${view["foot-wrap"]}`}>
        <div className={`${view["dealer-info"]}`}>

            { renderLazyItem && loadFooterImage() }
            <div className={view["call-block"]}>
              <h3>Call us on</h3>
              <a href={`tel:${dealerInfo.salesNumber}`} data-department="sales">Sales {dealerInfo.salesNumber}</a>
              <a href={`tel:${dealerInfo.serviceNumber}`} data-department="service">Service {dealerInfo.serviceNumber}</a>
            </div>
        </div>
        <div className={`${view["link-block"]} ${grid["col-lg-12"]} ${view["foot-links"]} ${grid["col-md-8"]} ${grid["col-sm-8"]} `}>
          {/*{renderLinks()}*/}
          {renderLinkColumns()}
          <div className={["stackdiv"]}>
            {(usableLink(footer.linkFB)
                || usableLink(footer.linkInsta)
                || usableLink(footer.linkTwit)
                || usableLink(footer.linkYelp)
                || usableLink(footer.linkYT)
                || usableLink(footer.linkLinked)) && renderSocialColumn()}
            {renderAppColumn()}
          </div>
        </div>
        <div className={`${view["subtitle-box"]} ${grid["col-lg-12"]}`} style={{ textAlign: "center" }}>
          {renderBottomLinks()}

          <div className={view["dm-box"]} style={{display: "flex", flexDirection: "column"}}>
          <CookiePolicy disclaimer={footer.cookieDisclaimer} dealerInfo={dealerInfo} CookieLinkText="Manage Cookie Policy" />
          <Link id='nav_link' to={"/do-not-sell-my-personal-information"} className={`${view["ccpa"]}`}>Do Not Sell My Personal Information</Link>
          <Link id='nav_link' to={"https://dealermasters.com/"} className={`${view["last"]} ${view["DM-spot"]}`}>
            <p style={{ display: "inline-block" }}>Made with</p>
            <span className={view["heart"]} aria-label={"heart"} role={"img"}> &#10084; ️</span>
            <p style={{ display: "inline-block" }}>by Dealer Masters</p>
          </Link>
          </div>
        </div>
      </div>

    </footer>
  )
}

export default Footer
